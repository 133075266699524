<template>
  <div
    class="d-flex justify-content-center align-items-center pt-5"
    :class="!modal ? 'bg-white' : ''"
  >
    <form @submit.prevent="manageItem()">
      <card
        class="card-register card-white text-left"
        :class="modal ? 'p-5 shadow-lg' : ''"
      >
        <div v-if="isRegisterBooking">
          <div class="header-login-required">
            <em class="tim-icons icon-alert-circle-exc mr-2 excIcon"></em>
            <h4 class="login-required">{{ $t("loginRequired") }}</h4>
          </div>
          <hr />
        </div>
        <h4 class="card-title mb-4">{{ $tc("routes.newUser") }}</h4>

        <div>
          <base-input
            v-model="user.name"
            max="255"
            :placeholder="$t('name')"
            addon-left-icon="tim-icons icon-single-02"
            class="mb-4"
            required
          />
  
          <base-input
            inputType="tel"
            v-model="user.phone"
            :placeholder="$t('phone')"
            addon-left-icon="tim-icons icon-mobile"
            class="mb-4"
            required
          />
  
          <div class="mb-4 inputContainer">
            <base-input
              inputType="email"
              v-model="user.email"
              :placeholder="$t('email')"
              addon-left-icon="tim-icons icon-email-85"
              :class="{'field-error': user && user.email && !isValidEmail}"
              class="mb-4"
              required
            />
            <small v-if="user && user.email && !isValidEmail" class="text-error-field">
              {{ $t('validationErrors.email') }}
            </small>
          </div>

          <div class="mb-4 inputContainer">
            <base-input
              v-model="user.password"
              @keydown.space.prevent
              name="password"
              inputType="password"
              :placeholder="$t('password')"
              addon-left-icon="tim-icons icon-lock-circle"
              :class="{'field-error': user && user.password && !isValidPassword}"
              required
            />
            <small v-if="user && user.password && !isValidPassword" class="text-error-field">
              {{ $t('validationErrors.password', [MINIMUM_PASSWORD_CHARACTERS]) }}
            </small>
          </div>
          
          <div class="mb4 inputContainer">
            <base-input
              v-model="user.passwordConfirm"
              @keydown.space.prevent
              name="password"
              inputType="password"
              :placeholder="$t('passwordConfirm')"
              addon-left-icon="tim-icons icon-lock-circle"
              :class="{'field-error': user && user.password && !isSamePassword}"
              class="mb-4"
              required
            />
            <small v-if="user && user.password && !isSamePassword" class="text-error-field">
              {{ $t('validationErrors.confirmPassword') }}
            </small>
          </div>

          <div class="d-flex align-items-center">
              <base-checkbox v-model="user.is_accepted_policy" class="policyCheckbox">
                  <span>{{ $t("acceptThe") }}</span>
              </base-checkbox>
              <router-link to="/politica-de-privacidad" target="_blank" class="policyLink">
                {{ $t('privacyPolicy') }}
              </router-link>
          </div>
        </div>

        <div slot="footer">
          <div class="pull-left">
            <h5>
              {{ $t("alreadyRegistered") }}
              <p
                class="link footer-link linkLogin"
                @click="isRegisterBooking ? $emit('showLogin', true) : goToLogin()"
              >
                {{ $t("login") }}
              </p>
            </h5>
          </div>

          <base-button
            :disabled="isBtnDisabled"
            native-type="submit"
            class="mt-4"
            type="success"
            size="lg"
            block
        >
          {{ $t("registerMe") }}
        </base-button>
        <base-button
              block
              type="danger"
              variant="danger"
              v-if="modal"
              size="lg"
              @click="$emit('changeModalShow', false)"
              >{{ $t("cancel") }}</base-button
            >
        </div>
      </card>
    </form>
  </div>
</template>

<script>
import { BaseButton, BaseInput, Card } from "@/components/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "Register",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    isRegisterBooking: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      route: "/register",
      user: {
        name: null,
        email: null,
        is_accepted_policy: false,
        phone: null,
        password: null,
        passwordConfirm: null,
      },
      MINIMUM_PASSWORD_CHARACTERS: 8,
    };
  },
  computed: {
    isSamePassword() {
      const { password, passwordConfirm } = this.user;
      return Boolean(password && passwordConfirm && password === passwordConfirm);
    },
    isValidEmail() {
      return this.user.email && /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.user.email)
    },
    isValidPassword() {
      const MINIMUM_CHARACTERS = 8; 
      return this.user.password.length >= MINIMUM_CHARACTERS;
    },
    isBtnDisabled() {
      return Object.values(this.user).some( value => !value ) || !this.isSamePassword || !this.isValidEmail || !this.isValidPassword;
    },
    ...mapState(["previousPage", "defaultTitle"]),
  },
  methods: {
    createItem() {
      let user = {
        name: this.user.name,
        email: this.user.email,
        is_accepted_policy: this.user.is_accepted_policy,
        phone: this.user.phone,
        password: this.user.password,
        password_confirmation: this.user.passwordConfirm,
      };
      if (user.password !== user.password_confirmation) {
        this.$toast.error(
          this.$t("notifications.error.passwordConfirmationNotMatch")
        );
        return;
      }
      return user;
    },
    manageItem() {
      const user = this.createItem();
      if (user !== undefined) this.registerUser(user);
    },
    async registerUser(user) {
      const route = "/auth/register";
      const response = await this.axios.post(route, user);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
        await this.login(user);
      }
    },
    async login(user) {
      const route = "/auth/login";
      const meRoute = "/me";
      const credentials = {
        email: user.email,
        password: user.password,
      };
      let response = await this.axios.post(route, credentials);
      if (response && response.data.status === "success") {
        const user = response.data.data.user;
        const jwt = response.data.data.jwt;
        const expirationDate = new Date(
          new Date().getTime() + jwt.expires_in * 1000
        );
        localStorage.setItem("userRole", user.role);
        this.$store.dispatch("setRole", user.role);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("api_token", jwt.token);
        localStorage.setItem("token_expires_in", expirationDate);
        this.axios.defaults.headers.common = {
          Authorization: `Bearer ${localStorage.getItem("api_token")}`,
        };
        let meResponse = await this.axios.get(meRoute);
        if (meResponse && meResponse.data.status === "success") {
          localStorage.setItem(
            "profilePicture",
            meResponse.data.data.user.picture
          );
        }
        this.$store.dispatch(
          "setAuthenticate",
          localStorage.getItem("api_token")
        );

        this.isRegisterBooking
          ? this.$emit("saveBooking")
          : this.goToPreviousPage();

        this.$toast.success(this.$t("welcome"));
      }
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    goToPreviousPage() {
      let pageTo = this.previousPage ? this.previousPage : "/";
      this.$router.push({ path: pageTo });
      this.$store.dispatch("setPreviousPage", null);
    },
    ...mapActions(["setPreviousPage"]),
  },
  metaInfo() {
    if(this.defaultTitle) {
      return { title: `${this.$t('register')} - ${this.defaultTitle}` }
    }
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';
.login-required {
  color: red;
  font-weight: 500;
  font-size: 14px;
}
.inputContainer {
  position: relative;
}
.field-error {
  outline: 1px solid $rejected;
  border-radius: 0.5rem;
}
.text-error-field {
  position: absolute;
  color: $rejected;
  padding-left: 1.2rem;
  top: 2.5rem;
}
.field-error {
  outline: 1px solid red;
  border-radius: 0.5rem;
}
.header-login-required {
  display: flex;
}
.excIcon {
  color: red;
  font-size: 20px;
}
.linkLogin {
  display: inline-block;
  cursor: pointer;
  color: #e87943!important;
}
.policyLink {
  margin: 6px 0 0 5px;
  font-size: 0.75rem;
  vertical-align: auto;
  font-weight: 600;
}
.policyCheckbox {
  margin: 5px 0 0 10px !important;
}
</style>
